<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Offre actuelle
      </h5>
      <b-badge v-if="userData.offre === 0" variant="light-primary">
        Facebook Ads
      </b-badge>
      <b-badge v-else-if="userData.offre === 1" variant="light-primary">
        Google Ads
      </b-badge>
      <b-badge v-else-if="userData.offre === 2" variant="light-primary">
        Google Ads + Facebook Ads
      </b-badge>
      <small class="text-muted w-100 mt-2 mb-1">July 22, 2021</small>
    </b-card-header>

    <b-card-body>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Mettre à jour
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
