<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      ref="refInvoiceListTable"
      :items="userData.factures"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="Aucune facture n'est disponible"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          class="font-weight-bold"
        >
          #{{ data.item.id }}
        </b-link>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.montant / 100 }}€ TTC
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(debut)="data">
        <span class="text-nowrap">
          {{ new Date(data.item.debut*1000).getDate() }}/{{ new Date(data.item.debut*1000).getMonth() + 1 }}/{{ new Date(data.item.debut*1000).getFullYear() }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(fin)="data">
        <span class="text-nowrap">
          {{ new Date(data.item.fin*1000).getDate() }}/{{ new Date(data.item.fin*1000).getMonth() + 1 }}/{{ new Date(data.item.fin*1000).getFullYear() }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}d
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)>
        <template>
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <a
            :href="data.item.pdf"
            target="_blank"
          >
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="DownloadIcon"
              class="cursor-pointer"
              size="16"
            />
          </a>
        </div>
      </template>

    </b-table>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BLink,
  BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
